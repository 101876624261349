import React, { useState, useEffect } from "react";
import { Modal, Input, Typography, Button, message } from "antd";
import { STATES } from "../../constants/Constants";
const { Text } = Typography;

const StateRiskCapsModal = ({
  visible,
  onClose,
  maxRiskPerStateInMillions,
  maxRiskPerRegionInMillions,
  saveTrue = false,
}) => {
  // For state-level caps
  const [localRiskCaps, setLocalRiskCaps] = useState({});
  // For region-level caps
  const [localRegionCaps, setLocalRegionCaps] = useState({});

  useEffect(() => {
    if (visible) {
      // --- For state caps ---
      const savedRiskCaps = JSON.parse(localStorage.getItem("riskCaps"));
      if (savedRiskCaps && Object.keys(savedRiskCaps).length > 0) {
        setLocalRiskCaps(savedRiskCaps);
      } else if (
        maxRiskPerStateInMillions &&
        Object.keys(maxRiskPerStateInMillions).length > 0
      ) {
        setLocalRiskCaps(maxRiskPerStateInMillions);
        localStorage.setItem(
          "riskCaps",
          JSON.stringify(maxRiskPerStateInMillions)
        );
      } else {
        setLocalRiskCaps({});
      }

      // --- For region caps ---
      const savedRegionCaps = JSON.parse(localStorage.getItem("regionCaps"));
      if (savedRegionCaps && Object.keys(savedRegionCaps).length > 0) {
        setLocalRegionCaps(savedRegionCaps);
      } else if (
        maxRiskPerRegionInMillions &&
        Object.keys(maxRiskPerRegionInMillions).length > 0
      ) {
        setLocalRegionCaps(maxRiskPerRegionInMillions);
        localStorage.setItem(
          "regionCaps",
          JSON.stringify(maxRiskPerRegionInMillions)
        );
      } else {
        // Default: set each region cap to an empty string
        const defaultRegionCaps = {};
        STATES.forEach((regionBlock) => {
          defaultRegionCaps[regionBlock.region] = "";
        });
        setLocalRegionCaps(defaultRegionCaps);
        localStorage.setItem("regionCaps", JSON.stringify(defaultRegionCaps));
      }
    }
  }, [visible, maxRiskPerStateInMillions, maxRiskPerRegionInMillions]);

  // Handler for state-level inputs
  const handleInputChange = (e, stateCode) => {
    const newVal = e.target.value;
    setLocalRiskCaps((prev) => ({
      ...prev,
      [stateCode]: newVal,
    }));
  };

  // Handler for region-level inputs
  const handleRegionInputChange = (e, region) => {
    const newVal = e.target.value;
    setLocalRegionCaps((prev) => ({
      ...prev,
      [region]: newVal,
    }));
  };

  // Save handler: Validate both state and region inputs.
  const handleSave = () => {
    // Validate state risk caps for negative values.
    const negativeStates = Object.keys(localRiskCaps).filter((stateCode) => {
      const value = parseFloat(localRiskCaps[stateCode]);
      return !isNaN(value) && value < 0;
    });
    if (negativeStates.length > 0) {
      message.error(
        "State risk cap values cannot be negative. Please correct the values."
      );
      return;
    }
    // Validate region risk caps for negative values.
    const negativeRegions = Object.keys(localRegionCaps).filter((region) => {
      const value = parseFloat(localRegionCaps[region]);
      return !isNaN(value) && value < 0;
    });
    if (negativeRegions.length > 0) {
      message.error(
        "Region risk cap values cannot be negative. Please correct the values."
      );
      return;
    }

    // Format the values for states.
    const formattedStateCaps = Object.keys(localRiskCaps).reduce((acc, key) => {
      acc[key] = parseFloat(localRiskCaps[key]);
      return acc;
    }, {});
    // Format the values for regions.
    const formattedRegionCaps = Object.keys(localRegionCaps).reduce(
      (acc, key) => {
        acc[key] = parseFloat(localRegionCaps[key]);
        return acc;
      },
      {}
    );

    // Update localStorage for both.
    localStorage.setItem("riskCaps", JSON.stringify(formattedStateCaps));
    localStorage.setItem("regionCaps", JSON.stringify(formattedRegionCaps));

    // Pass the combined data back to the parent.
    onClose({
      maxRiskPerStateInMillions: formattedStateCaps,
      maxRiskPerRegionInMillions: formattedRegionCaps,
    });
  };

  // Cancel handler: reset values
  const handleCancel = () => {
    setLocalRiskCaps({ ...maxRiskPerStateInMillions });
    // For region caps, if backend provides values, use them; otherwise, default to empty.
    if (
      maxRiskPerRegionInMillions &&
      Object.keys(maxRiskPerRegionInMillions).length > 0
    ) {
      setLocalRegionCaps({ ...maxRiskPerRegionInMillions });
    } else {
      const defaultRegionCaps = {};
      STATES.forEach((regionBlock) => {
        defaultRegionCaps[regionBlock.region] = "";
      });
      setLocalRegionCaps(defaultRegionCaps);
    }
    onClose();
  };

  // Check if there are any states to display (for state caps)
  const hasStates = STATES.some((regionBlock) =>
    regionBlock.states.some(
      (state) => maxRiskPerStateInMillions[state.value] !== undefined
    )
  );

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title="Region & State Risk Caps"
      width="95%"
      destroyOnClose
    >
      {/* MAIN CONTENT */}
      <div style={{ marginBottom: 20 }}>
        {hasStates ? (
          <div
            style={{
              display: "flex",
              gap: "3.5%",
              flexWrap: "nowrap",
              overflowX: "auto",
            }}
          >
            {STATES.map((regionBlock) => {
              const { region, states } = regionBlock;
              // Filter only those states that exist in maxRiskPerStateInMillions.
              const filteredStates = states.filter(
                (s) => maxRiskPerStateInMillions[s.value] !== undefined
              );
              if (filteredStates.length === 0) return null;
              return (
                <div key={region} style={{ minWidth: "10%" }}>
                  {/* Region Row: Region label and region cap input */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "18px",
                    }}
                  >
                    {/* Give region label a fixed width to align with state inputs */}
                    <div
                      style={{
                        width: "8rem",
                        whiteSpace: "normal",
                        paddingRight: "6px",
                      }}
                    >
                      <Text strong style={{ fontSize: "20px" }}>
                        {region}
                      </Text>
                    </div>
                    <Input
                      value={localRegionCaps[region] ?? ""}
                      onChange={(e) => handleRegionInputChange(e, region)}
                      suffix="M USD"
                      style={{ width: "8rem" }}
                    />
                  </div>
                  {/* List state inputs */}
                  {filteredStates.map((stateObj) => {
                    const { display: stateName, value: stateCode } = stateObj;
                    const currentVal = localRiskCaps[stateCode] ?? "";
                    return (
                      <div
                        key={stateCode}
                        style={{
                          display: "flex",
                          marginBottom: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "8rem",
                            whiteSpace: "normal",
                            paddingRight: "6px",
                          }}
                        >
                          {stateName}
                        </div>
                        <Input
                          value={currentVal}
                          onChange={(e) => handleInputChange(e, stateCode)}
                          suffix="M USD"
                          style={{ width: "8rem" }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Text type="secondary" style={{ fontSize: "16px" }}>
              No states found or 'No Caps' selected. Please change the option to
              'Impose Caps' and ensure the state data is filled.
            </Text>
          </div>
        )}
      </div>

      {/* FOOTER */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #F0F0F0",
          paddingTop: 16,
          marginTop: 16,
        }}
      >
        <div style={{ flex: 1 }}>
          <Button type="link" onClick={handleCancel} style={{ paddingLeft: 0 }}>
            CANCEL
          </Button>
        </div>
        {saveTrue && (
          <Button type="primary" onClick={handleSave}>
            SAVE
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default StateRiskCapsModal;
