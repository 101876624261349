import React, { useEffect, useState } from "react";
import Components from "components";
import { Typography, Input, Popover, Button } from "antd";
import { QuestionCircleFilled, CloseOutlined } from "@ant-design/icons";
import style from "./dashboard.module.scss";
import { Radio } from "../../../node_modules/antd/lib/index";
import StateRiskCapsModal from "../StateRiskCapsModal/StateRiskCapsModal";

const { Text } = Typography;

const DataAnalysisInput = ({
  isReadOnly,
  totalRisk,
  reinsurerRisk,
  averagePayout,
  percentageOfRisk,
  maxSale,
  lowCapFactor,
  highCapFactor,
  hotZonesTotalRisk,
  numberOfHotZones,
  premiumPerOutlock,
  popover,
  setpopover,
  analysisType,
  openNotification,
  notification,
  settotalRisk,
  setreinsurerRisk,
  setaveragePayout,
  setpercentageOfRisk,
  setmaxSale,
  setlowCapFactor,
  sethighCapFactor,
  sethotZonesTotalRisk,
  setnumberOfHotZones,
  setpremiumPerOutlock,
  setPolicyNumberModalVisible,
  selectedProduct,
  probabilityForPML,
  setProbabilityForPML,
  stateRisk,
  setstateRisk,
  saveSettingsPayload,
  setSaveSettingsPayload,
  showAllSelectedState,
  maxRiskPerStateInMillions,
  maxRiskPerRegionInMillions,
}) => {
  const [stateRiskCapsVisible, setStateRiskCapsVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // // Function to normalize state values
  // const normalizeStateValues = (stateInput) => {
  //   if (typeof stateInput === "string") {
  //     const stateArray = stateInput.split(",").map((state) => state.trim());
  //     return stateArray.reduce((acc, stateCode) => {
  //       acc[stateCode] = "";
  //       return acc;
  //     }, {});
  //   }
  //   return {};
  // };

  // Determine if in Edit mode
  // const isEditMode = Boolean(
  //   maxRiskPerStateInMillions &&
  //     Object.keys(maxRiskPerStateInMillions).length > 0
  // );

  // Prepare data for the modal
  // const modalData = isEditMode
  //   ? maxRiskPerStateInMillions
  //   : normalizeStateValues(showAllSelectedState);

  const handleStateRiskChange = (e) => {
    const value = e.target.value;
    setstateRisk(value);
    if (value === "Impose Caps") {
      setStateRiskCapsVisible(true);
    } else if (value === "No Caps") {
      setStateRiskCapsVisible(false);
      localStorage.removeItem("riskCaps");
      localStorage.removeItem("regionCaps");
    }
  };

  useEffect(() => {
    if (averagePayout) {
      const lowCap = Number(averagePayout);
      setlowCapFactor(lowCap.toString());
    } else {
      setlowCapFactor("");
      sethighCapFactor("");
    }
  }, [averagePayout]);

  // useEffect(() => {
  //   if (maxSale && lowCapFactor) {
  //     let highCap = (maxSale / 50) * lowCapFactor * 20;
  //     highCap = Number.isInteger(highCap)
  //       ? Number(highCap)
  //       : Number(highCap).toFixed(2);
  //     sethighCapFactor(highCap);
  //   }
  // }, [maxSale, lowCapFactor]);

  const handleProbabilityForPMLInputKeyDown = (evt) => {
    if (
      evt.key.toLowerCase() === "backspace" ||
      evt.key === "." ||
      evt.key.toLowerCase() === "tab"
    ) {
      return;
    }
    if (isNaN(evt.key)) {
      evt.preventDefault();
    }
  };

  const handleProbabilityForPMLInputKeyUp = (evt) => {
    const value = parseFloat(evt.target.value);
    if (value > 99.99999999) {
      notification.destroy();
      openNotification("Probability for PML should not exceed 99.99999999");
      setProbabilityForPML("");
    } else if (value <= 0) {
      notification.destroy();
      openNotification("Probability for PML should be greater than 0");
      setProbabilityForPML("");
    } else {
      notification.destroy();
    }
  };

  const handleDeepAnalysisInputKeyUp = async (evt) => {
    if (evt.target.value > 100) {
      notification.destroy();
      evt.target.value = null;
      // setalertMessage("% of Risk should be between 1 and 100");
      openNotification("% of Risk should be between 1 and 100");
    } else if (evt.target.value < 1) {
      notification.destroy();
      evt.target.value = null;
      // setalertMessage("% of Risk should be between 1 and 100");
      openNotification("% of Risk should be between 1 and 100");
    } else {
      notification.destroy();
      // setalertMessage(null);
    }
  };

  const handleDeepAnalysisInputKeyDown = (evt) => {
    if (
      evt.key.toLowerCase() === "backspace" ||
      evt.key.toLowerCase() === "." ||
      evt.key.toLowerCase() === "tab"
    ) {
      return;
    }
    if (isNaN(evt.key)) {
      evt.preventDefault();
    }
  };

  // // Function to merge maxRiskPerStateInMillions with normalizeStateValues
  // function mergeStateValues(normalizedValues, predefinedValues) {
  //   // Loop through each state code in the predefined values
  //   Object.keys(predefinedValues).forEach((stateCode) => {
  //     if (predefinedValues[stateCode]) {
  //       // If the state has a predefined value, override the normalized value with it
  //       normalizedValues[stateCode] = predefinedValues[stateCode];
  //     }
  //   });

  //   return normalizedValues;
  // }

  return (
    <>
      <div className="greyBackground">
        <div
          className={style.centerAlignContent}
          // style={isReadOnly ? { marginTop: 0 } : { marginTop: "20px" }}
        >
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <Components.FloatLabel
              labelName="Annual Premium Per Policy"
              inputName="premiumPerOutlock"
              valueText={premiumPerOutlock}
            >
              <Input
                id="premiumPerOutlock"
                autoComplete="off"
                size="large"
                readOnly={isReadOnly || false}
                style={{ height: "45px", paddingTop: "9px" }}
                suffix={
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    Thousand USD
                  </Text>
                }
                value={premiumPerOutlock}
                onKeyDown={(evt) => handleDeepAnalysisInputKeyDown(evt)}
                onChange={(e) => setpremiumPerOutlock(e.target.value)}
              />
            </Components.FloatLabel>
          </div>
          {!isReadOnly && (
            <div className={style.iconSection}>
              <Popover
                content={
                  <Text type="secondary">
                    The premium paid by a user to purchase a {selectedProduct}
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    Annual Premium Per Policy
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({ ...prev, premium: false }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                visible={popover.premium}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    premium: !popover.premium,
                  }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
            </div>
          )}
        </div>

        <div className={style.centerAlignContent}>
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <Components.FloatLabel
              labelName="Payout if Policy Triggers"
              inputName="averagePayout"
              valueText={averagePayout}
            >
              <Input
                id="averagePayout"
                autoComplete="off"
                size="large"
                readOnly={isReadOnly || false}
                style={{ height: "45px", paddingTop: "9px" }}
                suffix={
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    Thousand USD
                  </Text>
                }
                value={averagePayout}
                onKeyDown={(evt) => handleDeepAnalysisInputKeyDown(evt)}
                onChange={(e) => setaveragePayout(e.target.value)}
                onBlur={(e) => {
                  notification.destroy();
                  if (
                    lowCapFactor &&
                    Number(averagePayout) > Number(lowCapFactor)
                  ) {
                    setaveragePayout(null);
                    openNotification(
                      "Average payout should be less than or equal to Low Cap"
                    );
                    // setalertMessage(
                    //   "Average payout should be less than or equal to Low Cap"
                    // );
                  } else {
                    notification.destroy();
                    // setalertMessage(null);
                  }
                  // setvalidateTotalRisk(!validateTotalRisk);
                }}
              />
            </Components.FloatLabel>
          </div>
          {!isReadOnly && (
            <div className={style.iconSection}>
              <Popover
                content={
                  <Text type="secondary">
                    We are assuming for simplicity that all payouts are the
                    same. This field specifies what that amount is.
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    Payout if Policy Triggers
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({ ...prev, payout: false }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                visible={popover.payout}
                onVisibleChange={() =>
                  setpopover((prev) => ({ ...prev, payout: !popover.payout }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
            </div>
          )}
        </div>

        <div className={style.centerAlignContent}>
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <Components.FloatLabel
              labelName="How Many Policies Sold?"
              inputName="maxSale"
              valueText={maxSale}
            >
              <Input
                id="maxSale"
                autoComplete="off"
                size="large"
                // readOnly={true}
                readOnly={isReadOnly || false}
                style={{ height: "45px" }}
                value={maxSale}
                onChange={(e) => setmaxSale(e.target.value)}
                suffix={
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    Thousand Policies
                  </Text>
                }
                onBlur={(e) => {
                  if (Number(maxSale) > 1000000) {
                    setPolicyNumberModalVisible(
                      `We can simulate the sale of a maximum of 1,000,000 ${selectedProduct}s. The number of policies to be sold has been capped at this value.`
                    );
                  } else {
                    setPolicyNumberModalVisible(null);
                  }
                }}
              />
            </Components.FloatLabel>
          </div>
          {!isReadOnly && (
            <div className={style.iconSection}>
              <Popover
                content={
                  <Text type="secondary">
                    The maximum number of {selectedProduct}s that can be sold.
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    How Many Policies Sold?
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({ ...prev, maxSale: false }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                visible={popover.maxSale}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    maxSale: !popover.maxSale,
                  }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
            </div>
          )}
        </div>

        <div className={style.centerAlignContent} style={{ display: "none" }}>
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <Components.FloatLabel
              labelName="Payout Risk"
              inputName="percentageOfRisk"
              valueText={percentageOfRisk}
            >
              <Input
                id="percentageOfRisk"
                autoComplete="off"
                size="large"
                readOnly={isReadOnly || false}
                style={{ height: "45px" }}
                value={percentageOfRisk}
                onKeyDown={(evt) => handleDeepAnalysisInputKeyDown(evt)}
                onChange={(e) => setpercentageOfRisk(e.target.value)}
                onKeyUp={(evt) => handleDeepAnalysisInputKeyUp(evt)}
                suffix={
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    %
                  </Text>
                }
              />
            </Components.FloatLabel>
          </div>
          {!isReadOnly && (
            <div className={style.iconSection}>
              <Popover
                content={
                  <Text type="secondary">
                    Percentage of risk assumed on each {selectedProduct}.
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    Payout Risk
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({
                            ...prev,
                            percentageOfRisk: false,
                          }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                visible={popover.percentageOfRisk}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    percentageOfRisk: !popover.percentageOfRisk,
                  }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
            </div>
          )}
        </div>
      </div>
      <div className="greyBackground">
        <div style={{ marginBottom: "10px", fontSize: "16px" }}>
          <strong>RISK CAPS</strong>
        </div>
        <strong>Global</strong>
        <div className={style.centerAlignContent} style={{ marginTop: "10px" }}>
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <Components.FloatLabel
              labelName="Primary Payout Capacity"
              inputName="totalRisk"
              valueText={totalRisk}
            >
              <Input
                id="totalRisk"
                autoComplete="new-password"
                size="large"
                readOnly={isReadOnly || false}
                style={{ height: "45px", paddingTop: "9px" }}
                suffix={
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    Million USD
                  </Text>
                }
                value={totalRisk}
                onKeyDown={(evt) => handleDeepAnalysisInputKeyDown(evt)}
                onChange={(e) => {
                  settotalRisk(e.target.value);
                }}
              />
            </Components.FloatLabel>
          </div>
          {!isReadOnly && (
            <div className={style.iconSection}>
              <Popover
                content={
                  <Text type="secondary">
                    The risk in millions of USD that the primary insurer bears.
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    Primary Payout Capacity
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({
                            ...prev,
                            totalRisk: false,
                          }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                visible={popover.totalRisk}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    totalRisk: !popover.totalRisk,
                  }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
            </div>
          )}
        </div>

        <div className={style.centerAlignContent}>
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <Components.FloatLabel
              labelName="Total Risk Cap"
              inputName="reinsurerRisk"
              valueText={reinsurerRisk}
            >
              <Input
                id="reinsurerRisk"
                autoComplete="new-password"
                size="large"
                readOnly={isReadOnly || false}
                style={{ height: "45px", paddingTop: "9px" }}
                suffix={
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    Million USD
                  </Text>
                }
                value={reinsurerRisk}
                onKeyDown={(evt) => handleDeepAnalysisInputKeyDown(evt)}
                onChange={(e) => {
                  setreinsurerRisk(e.target.value);
                }}
              />
            </Components.FloatLabel>
          </div>
          {!isReadOnly && (
            <div className={style.iconSection}>
              <Popover
                content={
                  <Text type="secondary">
                    The risk in millions of USD that the reinsurer bears.
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    Total Risk Cap
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({
                            ...prev,
                            reinsurerRisk: false,
                          }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                visible={popover.reinsurerRisk}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    reinsurerRisk: !popover.reinsurerRisk,
                  }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
            </div>
          )}
        </div>

        {/* New Field for Probability for PML */}
        <div className={style.centerAlignContent}>
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <Components.FloatLabel
              labelName="Probability for PML"
              inputName="probabilityForPML"
              valueText={probabilityForPML}
            >
              <Input
                id="probabilityForPML"
                size="large"
                type="text"
                name="probabilityForPML"
                placeHolder="Probability for PML"
                autoComplete="off"
                readOnly={isReadOnly || false}
                style={{ height: "45px", paddingTop: "9px" }}
                suffix={
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    %
                  </Text>
                }
                value={probabilityForPML}
                onKeyDown={handleProbabilityForPMLInputKeyDown}
                onKeyUp={handleProbabilityForPMLInputKeyUp}
                onChange={(e) => setProbabilityForPML(e.target.value)}
              />
            </Components.FloatLabel>
          </div>
          {!isReadOnly && (
            <div className={style.iconSection}>
              <Popover
                content={
                  <Text type="secondary">
                    Probability percentage at which Probable Maximum Loss is
                    computed
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    Probability for PML
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({
                            ...prev,
                            probabilityForPML: false,
                          }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                visible={popover.probabilityForPML}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    probabilityForPML: !popover.probabilityForPML,
                  }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
            </div>
          )}
        </div>

        {/* New Field for State risk */}
        <strong style={{ marginBottom: "10px" }}>
          Region & State&nbsp;
          <span style={{ fontWeight: "400" }}>(risk per region & state)</span>
        </strong>
        <div className={style.centerAlignContent} style={{ marginTop: "5px" }}>
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {/* Radio Group for No Caps and Impose Caps */}
              <Components.FloatLabel valueText={stateRisk}>
                <Radio.Group
                  value={stateRisk}
                  onChange={handleStateRiskChange}
                  disabled={isReadOnly}
                >
                  <Radio value="No Caps">No Caps</Radio>
                  <Radio value="Impose Caps">Impose Caps</Radio>
                </Radio.Group>
                {stateRisk === "Impose Caps" ? (
                  <Button onClick={() => setModalOpen(true)}>
                    View Details
                  </Button>
                ) : null}
              </Components.FloatLabel>
            </div>
            {/* StateRiskCapsModal Component */}
            <StateRiskCapsModal
              visible={modalOpen}
              onClose={() => setModalOpen(false)}
              saveTrue={false}
              maxRiskPerStateInMillions={
                JSON.parse(localStorage.getItem("riskCaps")) || {}
              }
              maxRiskPerRegionInMillions={
                JSON.parse(localStorage.getItem("regionCaps")) || {}
              }
            />

            {/* <StateRiskCapsModal
              visible={modalOpen}
              onClose={() => setModalOpen(false)}
              saveTrue={true}
              // maxRiskPerStateInMillions={
              //   isEditMode
              //     ? normalizeStateValues(showAllSelectedState)
              //     : maxRiskPerStateInMillions
              // }
              maxRiskPerStateInMillions={
                isEditMode
                  ? mergeStateValues(
                      normalizeStateValues(showAllSelectedState),
                      maxRiskPerStateInMillions
                    )
                  : normalizeStateValues(showAllSelectedState)
              }
            /> */}
          </div>
          {!isReadOnly && (
            <StateRiskCapsModal
              visible={modalOpen}
              onClose={() => setModalOpen(false)}
              saveTrue={true}
              maxRiskPerStateInMillions={
                JSON.parse(localStorage.getItem("riskCaps")) || {}
              }
              maxRiskPerRegionInMillions={
                JSON.parse(localStorage.getItem("regionCaps")) || {}
              }
            />
          )}
        </div>

        <strong>
          Local&nbsp;
          <span style={{ fontWeight: "400" }}>
            (per {analysisType == "zip" ? "zip code" : analysisType})
          </span>
        </strong>
        <div className={style.centerAlignContent} style={{ display: "none" }}>
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <Components.FloatLabel
              labelName="Low Cap"
              inputName="lowCapFactor"
              valueText={lowCapFactor}
            >
              <Input
                id="lowCapFactor"
                size="large"
                type="text"
                name="lowCapFactor"
                placeHolder="lowCapFactor"
                autoComplete="off"
                readOnly={isReadOnly || false}
                disabled={!averagePayout}
                style={{ height: "45px", paddingTop: "9px" }}
                suffix={
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    Thousand USD
                  </Text>
                }
                value={lowCapFactor}
                onKeyDown={(evt) => handleDeepAnalysisInputKeyDown(evt)}
                onBlur={(e) => {
                  notification.destroy();
                  if (lowCapFactor && lowCapFactor < 0) {
                    setlowCapFactor(null);
                    openNotification(
                      "Low Cap should be greater than or equal to 0"
                    );
                    return;
                  }
                  if (
                    highCapFactor &&
                    Number(lowCapFactor) > Number(highCapFactor)
                  ) {
                    setlowCapFactor(null);
                    openNotification(
                      "Low Cap should be less than or equal to High Cap"
                    );
                    return;
                  }
                  notification.destroy();
                }}
                onChange={(e) => setlowCapFactor(e.target.value)}
              />
            </Components.FloatLabel>
          </div>
          {!isReadOnly && (
            <div className={style.iconSection}>
              <Popover
                content={
                  <Text type="secondary">
                    The risk cap that would be imposed on the 'lowest-activity'
                    {analysisType == "square"
                      ? " squares"
                      : analysisType == "zip"
                      ? " zip codes"
                      : " cities"}
                    .
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    Low Cap
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({
                            ...prev,
                            lowCapFactor: false,
                          }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                visible={popover.lowCapFactor}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    lowCapFactor: !popover.lowCapFactor,
                  }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
            </div>
          )}
        </div>

        <div className={style.centerAlignContent} style={{ marginTop: "10px" }}>
          <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
            <Components.FloatLabel
              labelName="High Cap"
              inputName="highCapFactor"
              valueText={highCapFactor}
            >
              <Input
                id="highCapFactor"
                size="large"
                name="highCapFactor"
                placeHolder="highCapFactor"
                disabled={!averagePayout}
                autoComplete="new-password"
                readOnly={isReadOnly || false}
                style={{ height: "45px", paddingTop: "9px" }}
                suffix={
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    Thousand USD
                  </Text>
                }
                onKeyDown={(evt) => handleDeepAnalysisInputKeyDown(evt)}
                onBlur={(e) => {
                  if (highCapFactor && highCapFactor <= 0) {
                    sethighCapFactor(null);
                    const key = openNotification(
                      "High Cap should be greater than or equal to payout"
                    );
                    setTimeout(() => notification.destroy(key), 2500);
                    return;
                  }
                  if (
                    lowCapFactor &&
                    Number(lowCapFactor) > Number(highCapFactor)
                  ) {
                    sethighCapFactor(null);
                    const key = openNotification(
                      "High Cap should be greater than or equal to payout"
                    );
                    setTimeout(() => notification.destroy(key), 2500);
                  } else {
                    notification.destroy();
                  }
                }}
                value={highCapFactor}
                onChange={(e) => sethighCapFactor(e.target.value)}
              />
            </Components.FloatLabel>
          </div>
          {!isReadOnly && (
            <div className={style.iconSection}>
              <Popover
                content={
                  <Text type="secondary">
                    The risk cap that would be imposed on the 'highest-activity'
                    {analysisType == "square"
                      ? " squares"
                      : analysisType == "zip"
                      ? " zip codes"
                      : " cities"}
                    .
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    High Cap
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({
                            ...prev,
                            highCapFactor: false,
                          }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                visible={popover.highCapFactor}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    highCapFactor: !popover.highCapFactor,
                  }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
            </div>
          )}
        </div>

        {analysisType == "square" || !(isReadOnly && analysisType == "zip") ? (
          <>
            <strong style={{ marginBottom: "10px" }}>
              Hot-Zone&nbsp;
              <span style={{ fontWeight: "400" }}>
                (per 9×9{" "}
                {analysisType == "square"
                  ? "squares"
                  : analysisType == "zip"
                  ? "zip codes"
                  : "cities"}
                )
              </span>
            </strong>
            <div
              className={style.centerAlignContent}
              style={{ marginTop: "10px" }}
            >
              <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
                <Components.FloatLabel
                  labelName="Number of Hot-Zones"
                  inputName="numberOfHotZones"
                  valueText={numberOfHotZones}
                >
                  <Input
                    id="numberOfHotZones"
                    size="large"
                    name="numberOfHotZones"
                    placeHolder="numberOfHotZones"
                    disabled={analysisType == "zip"}
                    autoComplete="off"
                    style={{ height: "45px" }}
                    readOnly={isReadOnly || false}
                    value={numberOfHotZones}
                    onKeyDown={(evt) => handleDeepAnalysisInputKeyDown(evt)}
                    onChange={(e) => {
                      notification.destroy();
                      if (e.target.value && Number(e.target.value) > 760) {
                        openNotification(
                          "Number of Hot-Zones cannot exceed 760"
                        );
                        setnumberOfHotZones(760);
                      } else {
                        setnumberOfHotZones(e.target.value);
                      }
                    }}
                    onBlur={() => notification.destroy()}
                  />
                </Components.FloatLabel>
              </div>
              {!isReadOnly && (
                <div className={style.iconSection}>
                  <Popover
                    content={
                      <Text type="secondary">The number of hot zones</Text>
                    }
                    title={
                      <div style={{ width: "100%", display: "flex" }}>
                        Number of Hot-Zones
                        <span style={{ position: "absolute", right: "23px" }}>
                          <CloseOutlined
                            style={{ color: "rgba(0, 0, 0, 0.45)" }}
                            onClick={() =>
                              setpopover((prev) => ({
                                ...prev,
                                numberOfHotZones: false,
                              }))
                            }
                          />
                        </span>
                      </div>
                    }
                    trigger="click"
                    placement="bottomRight"
                    visible={popover.numberOfHotZones}
                    onVisibleChange={() =>
                      setpopover((prev) => ({
                        ...prev,
                        numberOfHotZones: !popover.numberOfHotZones,
                      }))
                    }
                  >
                    <QuestionCircleFilled
                      className={style.questionCircleIcon}
                    />
                  </Popover>
                </div>
              )}
            </div>

            <div className={style.centerAlignContent}>
              <div style={isReadOnly ? { width: "100%" } : { width: "92%" }}>
                <Components.FloatLabel
                  labelName="Maximum Risk per Hot-Zone"
                  inputName="hotZonesTotalRisk"
                  valueText={hotZonesTotalRisk}
                >
                  <Input
                    id="hotZonesTotalRisk"
                    size="large"
                    name="hotZonesTotalRisk"
                    placeHolder="hotZonesTotalRisk"
                    disabled={analysisType == "zip"}
                    autoComplete="off"
                    readOnly={isReadOnly || false}
                    value={hotZonesTotalRisk}
                    onKeyDown={(evt) => handleDeepAnalysisInputKeyDown(evt)}
                    style={{ height: "45px", paddingTop: "9px" }}
                    onChange={(e) => sethotZonesTotalRisk(e.target.value)}
                    suffix={
                      <Text type="secondary" style={{ fontSize: "14px" }}>
                        Million USD
                      </Text>
                    }
                  />
                </Components.FloatLabel>
              </div>
              {!isReadOnly && (
                <div className={style.iconSection}>
                  <Popover
                    content={
                      <Text type="secondary">
                        The maximum risk that can be sold in any hot zone.
                      </Text>
                    }
                    title={
                      <div style={{ width: "100%", display: "flex" }}>
                        Maximum Risk per Hot-Zone
                        <span style={{ position: "absolute", right: "23px" }}>
                          <CloseOutlined
                            style={{ color: "rgba(0, 0, 0, 0.45)" }}
                            onClick={() =>
                              setpopover((prev) => ({
                                ...prev,
                                hotZonesTotalRisk: false,
                              }))
                            }
                          />
                        </span>
                      </div>
                    }
                    trigger="click"
                    placement="bottomRight"
                    visible={popover.hotZonesTotalRisk}
                    onVisibleChange={() =>
                      setpopover((prev) => ({
                        ...prev,
                        hotZonesTotalRisk: !popover.hotZonesTotalRisk,
                      }))
                    }
                  >
                    <QuestionCircleFilled
                      className={style.questionCircleIcon}
                    />
                  </Popover>
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>

      <div style={{ display: "none" }}>
        <input
          type="text"
          id="PreventChromeAutocomplete"
          name="PreventChromeAutocomplete"
          autoComplete="address-level4"
        />
      </div>
    </>
  );
};

export default DataAnalysisInput;
