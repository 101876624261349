export const USER_ROLES = {
  ADMIN: "admin",
  BROKER: "broker",
  DISTRIBUTOR: "distributor",
  APP_USER: "appuser",
};

export const RULES = {};

export const AUTH_TOKENS = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
};

export const AUTH_USER = {
  AUTH_USER: "auth_token",
};

export const STATE_DISPLAY_TYPE = [
  { key: "all", value: "all", display: "All States" },
  {
    key: "mainland-states",
    value: "mainland-states",
    display: "48 contiguous states",
  },
  {
    key: "population",
    value: "population",
    display: "Top 10 states by population",
  },
  {
    key: "transactions",
    value: "transactions",
    display: "Top 10 states by transactions",
  },
  {
    key: "user-preference",
    value: "user-preference",
    display: "Let me choose",
  },
];

export const STATES = [
  {
    region: "Northeast",
    states: [
      {
        display: "Connecticut",
        value: "CT",
        status: true,
        population: "3.6",
        square: "381",
      },
      {
        display: "Maine",
        value: "ME",
        status: true,
        population: "1.4",
        square: "145",
      },
      {
        display: "Massachusetts",
        value: "MA",
        status: true,
        population: "7.0",
        square: "688",
      },
      {
        display: "New Hampshire",
        value: "NH",
        status: true,
        population: "1.4",
        square: "160",
      },
      {
        display: "New Jersey",
        value: "NJ",
        status: true,
        population: "9.3",
        square: "674",
      },
      {
        display: "New York",
        value: "NY",
        status: true,
        population: "20.2",
        square: "1281",
      },
      {
        display: "Pennsylvania",
        value: "PA",
        status: true,
        population: "13.0",
        square: "1008",
      },
      {
        display: "Rhode Island",
        value: "RI",
        status: true,
        population: "1.1",
        square: "95",
      },
      {
        display: "Vermont",
        value: "VT",
        status: true,
        population: "0.6",
        square: "75",
      },
    ],
  },
  {
    region: "East",
    states: [
      {
        display: "Delaware",
        value: "DE",
        status: true,
        population: "1.0",
        square: "85",
      },
      {
        display: "District of Columbia",
        value: "DC",
        status: true,
        population: "0.7",
        square: "29",
      },
      {
        display: "Maryland",
        value: "MD",
        status: true,
        population: "6.2",
        square: "487",
      },
      {
        display: "Virginia",
        value: "VA",
        status: true,
        population: "8.6",
        square: "747",
      },
    ],
  },
  {
    region: "Southeast",
    states: [
      {
        display: "North Carolina",
        value: "NC",
        status: true,
        population: "10.4",
        square: "900",
      },
      {
        display: "South Carolina",
        value: "SC",
        status: true,
        population: "5.1",
        square: "473",
      },
      {
        display: "Georgia",
        value: "GA",
        status: true,
        population: "10.7",
        square: "997",
      },
      {
        display: "Arkansas",
        value: "AR",
        status: true,
        population: "3.0",
        square: "283",
      },
      {
        display: "Kentucky",
        value: "KY",
        status: true,
        population: "4.5",
        square: "417",
      },
      {
        display: "Tennessee",
        value: "TN",
        status: true,
        population: "6.9",
        square: "673",
      },
      {
        display: "West Virginia",
        value: "WV",
        status: true,
        population: "1.8",
        square: "189",
      },
      {
        display: "Oklahoma",
        value: "OK",
        status: true,
        population: "4.0",
        square: "365",
      },
    ],
  },
  {
    region: "Gulf",
    states: [
      {
        display: "Florida",
        value: "FL",
        status: true,
        population: "21.5",
        square: "1809",
      },
      {
        display: "Alabama",
        value: "AL",
        status: true,
        population: "5.0",
        square: "453",
      },
      {
        display: "Mississippi",
        value: "MS",
        status: true,
        population: "3.0",
        square: "279",
      },
      {
        display: "Louisiana",
        value: "LA",
        status: true,
        population: "4.7",
        square: "424",
      },
      {
        display: "Texas",
        value: "TX",
        status: true,
        population: "29.1",
        square: "2474",
      },
    ],
  },
  {
    region: "Midwest",
    states: [
      {
        display: "Iowa",
        value: "IA",
        status: true,
        population: "3.2",
        square: "330",
      },
      {
        display: "Illinois",
        value: "IL",
        status: true,
        population: "12.8",
        square: "1190",
      },
      {
        display: "Indiana",
        value: "IN",
        status: true,
        population: "6.8",
        square: "739",
      },
      {
        display: "Kansas",
        value: "KS",
        status: true,
        population: "2.9",
        square: "310",
      },
      {
        display: "Michigan",
        value: "MI",
        status: true,
        population: "10.1",
        square: "1116",
      },
      {
        display: "Missouri",
        value: "MO",
        status: true,
        population: "6.2",
        square: "610",
      },
      {
        display: "Minnesota",
        value: "MN",
        status: true,
        population: "5.7",
        square: "517",
      },
      {
        display: "North Dakota",
        value: "ND",
        status: true,
        population: "0.8",
        square: "71",
      },
      {
        display: "Nebraska",
        value: "NE",
        status: true,
        population: "2.0",
        square: "170",
      },
      {
        display: "Ohio",
        value: "OH",
        status: true,
        population: "11.8",
        square: "1340",
      },
      {
        display: "South Dakota",
        value: "SD",
        status: true,
        population: "0.9",
        square: "80",
      },
      {
        display: "Wisconsin",
        value: "WI",
        status: true,
        population: "5.9",
        square: "576",
      },
    ],
  },
  {
    region: "West",
    states: [
      {
        display: "Alaska",
        value: "AK",
        status: true,
        population: "0.7",
        square: "47",
      },
      {
        display: "Arizona",
        value: "AZ",
        status: true,
        population: "7.2",
        square: "522",
      },
      {
        display: "Colorado",
        value: "CO",
        status: true,
        population: "5.7",
        square: "500",
      },
      {
        display: "Idaho",
        value: "ID",
        status: true,
        population: "1.8",
        square: "137",
      },
      {
        display: "Montana",
        value: "MT",
        status: true,
        population: "1.1",
        square: "113",
      },
      {
        display: "Nevada",
        value: "NV",
        status: true,
        population: "3.1",
        square: "226",
      },
      {
        display: "New Mexico",
        value: "NM",
        status: true,
        population: "2.1",
        square: "179",
      },
      {
        display: "California",
        value: "CA",
        status: true,
        population: "39.5",
        square: "2529",
      },
      {
        display: "Hawaii",
        value: "HI",
        status: true,
        population: "1.5",
        square: "86",
      },
      {
        display: "Oregon",
        value: "OR",
        status: true,
        population: "4.2",
        square: "340",
      },
      {
        display: "Washington",
        value: "WA",
        status: true,
        population: "7.7",
        square: "584",
      },
      {
        display: "Utah",
        value: "UT",
        status: true,
        population: "3.3",
        square: "168",
      },
      {
        display: "Wyoming",
        value: "WY",
        status: true,
        population: "0.6",
        square: "65",
      },
    ],
  },
];

export const STATES_BY_POPULATION = [
  { display: "California", value: "CA" },
  { display: "Texas", value: "TX" },
  { display: "Florida", value: "FL" },
  { display: "New York", value: "NY" },
  { display: "Pennsylvania", value: "PA" },
  { display: "Illinois", value: "IL" },
  { display: "Ohio", value: "OH" },
  { display: "Georgia", value: "GA" },
  { display: "North Carolina", value: "NC" },
  { display: "Michigan", value: "MI" },
];

export const STATES_BY_TRANSACTION = [
  { display: "California", value: "CA" },
  { display: "Texas", value: "TX" },
  { display: "New York", value: "NY" },
  { display: "Florida", value: "FL" },
  { display: "Illinois", value: "IL" },
  { display: "Pennsylvania", value: "PA" },
  { display: "Ohio", value: "OH" },
  { display: "New Jersey", value: "NJ" },
  { display: "Georgia", value: "GA" },
  { display: "Washington", value: "WA" },
];

export const MAINLAND_STATES = [
  { display: "Alabama", value: "AL" },
  { display: "Arizona", value: "AZ" },
  { display: "Arkansas", value: "AR" },
  { display: "California", value: "CA" },
  { display: "Colorado", value: "CO" },
  { display: "Connecticut", value: "CT" },
  { display: "Delaware", value: "DE" },
  { display: "Florida", value: "FL" },
  { display: "Georgia", value: "GA" },
  { display: "Idaho", value: "ID" },
  { display: "Illinois", value: "IL" },
  { display: "Indiana", value: "IN" },
  { display: "Iowa", value: "IA" },
  { display: "Kansas", value: "KS" },
  { display: "Kentucky", value: "KY" },
  { display: "Louisiana", value: "LA" },
  { display: "Maine", value: "ME" },
  { display: "Maryland", value: "MD" },
  { display: "Massachusetts", value: "MA" },
  { display: "Michigan", value: "MI" },
  { display: "Minnesota", value: "MN" },
  { display: "Mississippi", value: "MS" },
  { display: "Missouri", value: "MO" },
  { display: "Montana", value: "MT" },
  { display: "Nebraska", value: "NE" },
  { display: "Nevada", value: "NV" },
  { display: "New Hampshire", value: "NH" },
  { display: "New Jersey", value: "NJ" },
  { display: "New Mexico", value: "NM" },
  { display: "New York", value: "NY" },
  { display: "North Carolina", value: "NC" },
  { display: "North Dakota", value: "ND" },
  { display: "Ohio", value: "OH" },
  { display: "Oklahoma", value: "OK" },
  { display: "Oregon", value: "OR" },
  { display: "Pennsylvania", value: "PA" },
  { display: "Rhode Island", value: "RI" },
  { display: "South Carolina", value: "SC" },
  { display: "South Dakota", value: "SD" },
  { display: "Tennessee", value: "TN" },
  { display: "Texas", value: "TX" },
  { display: "Utah", value: "UT" },
  { display: "Vermont", value: "VT" },
  { display: "Virginia", value: "VA" },
  { display: "Washington", value: "WA" },
  { display: "West Virginia", value: "WV" },
  { display: "Wisconsin", value: "WI" },
  { display: "Wyoming", value: "WY" },
];

export const EXCLUSION_LIST = {
  "WHO pandemic declaration": "WHO",
  "State-wide lockdowns": "STW",
};

export const PRODUCT_JSON = (
  externalName,
  productName,
  triggerDrop,
  triggerPercent,
  triggerDurationValue,
  states,
  highCap,
  lowCap,
  totalRisk,
  numberOfPolicy,
  numberOfHotZone,
  maxRiskPerHotzone,
  payout,
  exclusionArr,
  generationTime,
  saleEndDate,
  saleStartDate
) => ({
  productDetails: {
    brief_desc: "Secure your business against events beyond your control",
    detailed_desc: "",
    locals: [
      {
        local: "en-US",
      },
    ],
    product_external_name: externalName,
    product_family: productName,
    product_internal_name: "",
    product_notes: "",
    type_of_product: "Full",
    product_status: "In progress",
    product_updated_at: generationTime,
  },
  trigger_duration_in_months: triggerDurationValue,
  percentage_of_squares_with_highest_trigger_propensity_dropped: triggerDrop,
  contractInformation: {
    contract_activation: [
      {
        desc: `CRC confirms that customer has bought ${productName} policy`,
      },
      {
        desc: "1st day of next month from policy purchase date is reached",
        prevOperator: "AND",
      },
    ],
    contract_activation_description:
      "CRC confirmation and 1st day of next month reached",
    contract_duration_number: "365",
    contract_duration_type: "Day",
    contract_exclusions: [...exclusionArr],
    contract_start_restrictions: "1st day of the month",
    contract_template_URL: `https://template_${productName}.machinecover.com`,
    entity_field_type: [
      {
        value: "EIN",
      },
      {
        value: "US street address",
      },
    ],
    extraordinary_trigger_conditions: "None",
    fixed_number_of_triggers: true,
    number_of_triggers: "1",
    premature_termination_condition: [
      {
        desc: "CRC cancels customer policy",
      },
    ],
    premature_termination_description: "CRC cancels customer policy",
    trigger_conditions: [
      {
        desc: `Difference between the ratio of aggregate MasterCard transaction amounts for the business area during (M-1) & (M-2) and the ratio of aggregate MasterCard transaction amounts for the entire nation during the same (M-1) & (M-2) is less than -${triggerPercent}%`,
      },
      {
        desc: `Difference between the ratio of aggregate MasterCard transaction amounts for the business area during (M-1) & (M-2) and the ratio of aggregate MasterCard transaction amounts for the business area during (M-13) & (M-14) is less than -${triggerPercent}%`,
        prevOperator: "AND",
      },
      {
        desc: `Difference between the ratio of aggregate MasterCard transaction amounts for the business area during M & (M-2) and the ratio of aggregate MasterCard transaction amounts for the entire nation during M & (M-2) is less than -${triggerPercent}%`,
        prevOperator: "AND",
      },
      {
        desc: `Difference between the ratio of aggregate MasterCard transaction counts for the business area during (M-1) & (M-2) and the ratio of aggregate MasterCard transaction counts for the entire nation during the same (M-1) & (M-2) is less than -${triggerPercent}%`,
        prevOperator: "AND",
      },
      {
        desc: `Difference between the ratio of aggregate MasterCard transaction counts for the business area during (M-1) & (M-2) and the ratio of aggregate MasterCard transaction counts for the business area during (M-13) & (M-14) is less than -${triggerPercent}%`,
        prevOperator: "AND",
      },
      {
        desc: `Difference between the ratio of aggregate MasterCard transaction counts for the business area during M & (M-2) and the ratio of aggregate MasterCard transaction counts for the entire nation during M & (M-2) is less than -${triggerPercent}%`,
        prevOperator: "AND",
      },
    ],
    trigger_description: `${triggerPercent}% dip in economic activity`,
    type_of_entity: "A business",
    warning: [
      {
        warning_condition: [
          {
            desc: `Difference between the ratio of aggregate MasterCard transaction amounts for the business area during (M-1) & (M-2) and the ratio of aggregate MasterCard transaction amounts for the entire nation during the same (M-1) & (M-2) is less than -${
              triggerPercent / 2
            }%`,
          },
        ],
        warning_delivered_via: "Email",
        warning_description: `${
          triggerPercent / 2
        }% dip in economic activity in the square`,
      },
    ],
  },
  sales: {
    allow_manual_shutoffs: true,
    automatic_shutoff_factor: "1.0",
    availability: [
      {
        country: "United States",
        state_name: [...states],
      },
    ],
    broker_restrictions: "None",
    dynamic_blacklist: [
      {
        desc: `Difference between the ratio of aggregate MasterCard transaction amounts for the business area during (M-1) & (M-2) and the ratio of aggregate MasterCard transaction amounts for the entire nation during the same (M-1) & (M-2) is less than -${
          triggerPercent / 2
        }% for any month in the past 1 year`,
      },
    ],
    dynamic_blacklist_description: `A region that has seen greater than ${
      triggerPercent / 2
    }% dip in economic activity during any month in the past 1 year`,
    enable_hot_zone_limit: true,
    geographic_restrictions: {},
    high_cap: `${highCap * 1000}`,
    high_cap_percentile: "100%",
    hot_zone_caps: `${maxRiskPerHotzone * 1000000}`,
    individual_sale_restrictions: "None",
    low_cap: `${lowCap * 1000}`,
    low_cap_percentile: "0%",
    maximum_amount_at_risk_for_broker: `${totalRisk * 1000000}`,
    maximum_amount_of_risk_at_any_time: `${totalRisk * 1000000}`,
    maximum_contracts_per_broker: `${numberOfPolicy * 1000}`,
    maximum_number_of_contracts_that_can_be_sold: `${numberOfPolicy * 1000}`,
    number_of_contracts: `${numberOfPolicy * 1000}`,
    number_of_days_contract: "1",
    number_of_days_risk: "1",
    number_of_hot_zone_caps: `${numberOfHotZone}`,
    number_of_risk: 0,
    other_constraints_on_contracts: "None",
    other_constraints_on_risk: "None",
    other_global_constraints: {
      value: "None",
    },
    sale_end_date: `${saleEndDate}`,
    sale_mode: "Broker-assisted",
    sale_start_date: `${saleStartDate}`,
    sales_exclusions: "None",
    trigger_shutoff_days: "180",
  },
  pricing: {
    margin_multiplier: "1",
    pricing_formula: "Simplified function A*P+B",
    tweak_condition: {
      value: "None",
    },
    value_of_A: "0.02",
    value_of_B: "0",
  },
  payIn: {
    payin_handler: "External party handles pay-in",
  },
  payOut: {
    maximum_payout_amount: `${payout * 1000}`,
    minimum_payout_amount: `${payout * 1000}`,
    number_of_notches: "1",
    payout_deadline: "5",
    payout_mode: "Direct - payment made by Machine Cover by check",
    payout_range_condition: {
      value: "None",
    },
    payout_requirements: {
      value:
        "Tax document needs to be provided (provided by policy buyer) and needs to match with the following assertions made during the purchase: (1) Business information (name, address) should match (2) Annual revenue as reported Federal return should exceed $500,000",
    },
    recommended_payout_amount_calculation: "50 X premium",
  },
  notification: {
    contract_specific_notifications: {
      activation_notification: true,
      completion_of_all_payout_requirements: true,
      completion_of_individual_payout_requirement: true,
      contract_abnormal_termination: true,
      contract_expiration: true,
      contract_pre_expiration: true,
      contract_purchase_completion: true,
      initial_trigger_notification: true,
      successful_payout_via_cheque: true,
      successful_payout_via_paypal: false,
    },
    number_of_days_before_expiry: "10",
    product_specific_notifications: {
      max_contracts_notification: true,
      max_contracts_per_broker_notification: true,
      max_risk_notification: true,
      max_risk_per_broker_notification: true,
      payout_per_broker_limit: `${totalRisk * 1000000}`,
      payout_per_broker_warning: true,
      payout_per_day_limit: `${(totalRisk * 1000000) / 100}`,
      payout_per_day_warning: true,
      payout_per_month_limit: `${(totalRisk * 1000000) / 4}`,
      payout_per_month_warning: true,
      payout_per_week_limit: `${(totalRisk * 1000000) / 10}`,
      payout_per_week_warning: true,
      payout_total_amount_limit: `${totalRisk * 1000000}`,
      total_payout_warning: true,
    },
    system_specific_notifications: {
      account_updated: true,
      contract_bulk_upload: true,
      creation_of_an_account: true,
    },
  },
  report: {
    daily_contract_summary: "true",
    daily_contracts_triggered_summary: "false",
    ledger_summary_and_transactions: "true",
    weekly_contract_summary: "false",
  },
});
